import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";
import Image4 from "../../assets/testimonial3.png";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: "Carl Allan",
    description:
      "The freelancer understood the task description well and conducted the work appropriately and within the agreed timeline. There was a slight issue with the site (caching) after the landing page was created, but he fixed it in a timely manner after being notified about it.",
  },
  {
    id: 2,
    image: Image1,
    title: "Carl Allan",
    description:
      "The freelancer is a great communicator, does the work according to the deadline and even provides additional recommendations to ensure the quality of his performance.",
  },
  {
    id: 3,
    image: Image1,
    title: "John",
    description:
      "Pavle is a hard-working guy, who will take calls any time of the day even though in a different time zone and that made a difference in our relationship with him.",
  },
  {
    id: 4,
    image: Image2,
    title: "Julia",
    description:
      "We enjoyed working with Pavle. He's committed, he keeps his word, is very supportive, and easy in communication. I definitely recommend this freelancer",
  },
  {
    id: 5,
    image: Image1,
    title: "Jamal",
    description:
      "I would love to recommend Pavle to anyone who has some technical issues with their Wordpress site. Pavle was working on increasing of speed of my site and did an excellent job. He went beyond what was requested and provided loads of useful advice.",
  },
  {
    id: 6,
    image: Image1,
    title: "Andy",
    description:
      "Definitely a talented developer - Pavle quickly solved the task given. I really liked that he gave med several solutions and help test after implementation. Highly recommended, will definitely hire again.",
  },
];
