import Triangle from "../../assets/triangle-logo.jpg";
import Maison from "../../assets/maison-logo.jpg";
import Custom from "../../assets/custom-logo.jpg";
import Ehom from "../../assets/ehom-logo.png";
import Sports from "../../assets/sports-logo-black.svg";
import WPspeed from "../../assets/logo-wpspeed.png";

export const projectsData = [
  {
    id: 1,
    image: Triangle,
    title: "Triangle TV",
    category: "web",
    link: "https://triangletv.rs/",
  },
  {
    id: 2,
    image: Custom,
    title: "Custom Surgical",
    category: "web",
    link: "https://customsurgical.co/",
  },
  {
    id: 3,
    image: Sports,
    title: "Sportsmen Lodge",
    category: "web",
    link: "https://www.shopsatsportsmenslodge.com/",
  },
  {
    id: 4,
    image: Ehom,
    title: "Ehom Shop",
    category: "woo",
    link: "https://shop.ehom.co.rs/",
  },
  {
    id: 5,
    image: Maison,
    title: "Maison Adorable",
    category: "woo",
    link: "https://maisonadorable.rs/",
  },
  {
    id: 6,
    image: WPspeed,
    title: "WP Speed",
    category: "web",
    link: "https://wpspeedopt.net/",
  },
];

export const projectNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
  {
    name: "woo",
  },
];
