import React from "react";
import upWork from "../../assets/upwork-icon.svg";

const Social = () => {
  return (
    <div className="home__social">
      <a
        href="https://github.com/payche011"
        className="home__social-icon"
        target="_blank"
      >
        <i class="uil uil-github-alt"></i>
      </a>
      <a
        href="https://www.linkedin.com/in/pavle-marinkovic/"
        className="home__social-icon home__social-linkedin"
        target="_blank"
      >
        <i class="uil uil-linkedin-alt"></i>
      </a>
      <a
        href="https://www.upwork.com/freelancers/~01b964046dfd7ed156"
        className="home__social-icon"
        target="_blank"
      >
        <img src={upWork} alt="" />
      </a>
    </div>
  );
};

export default Social;
