import React from "react";
import "./footer.css";
import upWork from "../../assets/upwork-icon.svg";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h5 className="footer__title">Pavle</h5>
        <ul className="footer__list">
          <li>
            <a href="#about" className="footer__link">
              About
            </a>
          </li>
          <li>
            <a href="#portfolio" className="footer__link">
              Projects
            </a>
          </li>
          <li>
            <a href="#testemonials" className="footer__link">
              Testemonials
            </a>
          </li>
        </ul>
        <div className="footer__social">
          <a
            href="https://github.com/payche011"
            className="home__social-icon"
            target="_blank"
          >
            <i class="uil uil-github-alt"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/pavle-marinkovic/"
            className="home__social-icon home__social-linkedin"
            target="_blank"
          >
            <i class="uil uil-linkedin-alt"></i>
          </a>
          <a
            href="https://www.upwork.com/freelancers/~01b964046dfd7ed156"
            className="home__social-icon"
            target="_blank"
          >
            <img src={upWork} alt="" />
          </a>
        </div>
        <span className="footer__copy">
          Copyright © {new Date().getFullYear()} Pavle Marinkovic
        </span>
      </div>
    </footer>
  );
}

export default Footer;
