import React from "react";
import { useState, useEffect } from "react";
import "./colors.css";

function Colors({ color }) {
  const themes = {
    light: "light",
    dark: "dark",
    blue: "blue",
    black: "black",
  };

  const toggleTheme = (theme, setTheme) => (newTheme) => {
    if (themes[newTheme]) {
      setTheme(newTheme);
      localStorage.setItem("theme", newTheme);
    }
  };

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || themes.light
  );

  const handleToggleTheme = toggleTheme(theme, setTheme);

  useEffect(() => {
    localStorage.setItem("theme", theme);
    document.body.className = theme;
  }, [theme]);

  return (
    <section className="colors" id="colors">
      <div className="container">
        <div className="colors__box">
          <div className="colors__box-title">
            <h2>Settings</h2>
            <h3>Color Palette</h3>
          </div>
          <div className="colors__box-buttons">
            <button
              className="colors__box-light"
              onClick={() => handleToggleTheme(themes.light)}
            ></button>
            <button
              className="colors__box-dark"
              onClick={() => handleToggleTheme(themes.dark)}
            ></button>
            <button
              className="colors__box-blue"
              onClick={() => handleToggleTheme(themes.blue)}
            ></button>
            <button
              className="colors__box-black"
              onClick={() => handleToggleTheme(themes.black)}
            ></button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Colors;
